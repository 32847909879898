import Network from '@/services/network'
import { ref } from 'vue'

export default function useReport () {
    const network = new Network
    const receivable = ref([])
    const payable = ref([])
    const cashBank = ref([])
    const fetchReceivable = (query) => {
        network.api('get', '/dashboard/accounting/receivable'+query)
        .then(res => {
            receivable.value = res.data.data
        })
    }
    const fetchPayable = (query) => {
        network.api('get', '/dashboard/accounting/payable'+query)
        .then(res => {
            payable.value = res.data.data
        })
    }
    const fetchCashBank = (query) => {
        network.api('get', '/dashboard/accounting/cash-and-bank'+query)
        .then(res => {
            cashBank.value = res.data.data
        })
    }

    return {
        receivable,
        payable,
        cashBank,
        fetchReceivable,
        fetchPayable,
        fetchCashBank,
    }
}